import React, { useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import api from './Services/api';
import AuthContext from './Context/AuthContext';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import Loader from './components/Loader'; // Loader component for fallback during lazy loading

// Lazy-loaded components
const Login = React.lazy(() => import('./components/Login'));
const Register = React.lazy(() => import('./components/Register'));
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const Calendar = React.lazy(() => import('./components/Calendar'));
const QRPricingTags = React.lazy(() => import('./components/QRPricingTags'));
const DynamicPricingTags = React.lazy(() => import('./components/DynamicPricingTags'));
const LoanCalculator = React.lazy(() => import('./components/LoanCalculator'));
const CRM = React.lazy(() => import('./components/CRM'));
const Financing = React.lazy(() => import('./components/Financing'));
const AccountSettings = React.lazy(() => import('./components/AccountSettings'));
const WorkflowBoard = React.lazy(() => import('./components/WorkflowBoard')); // Added WorkflowBoard

function App() {
    const { authToken } = useContext(AuthContext);

    useEffect(() => {
        api.get('/status')
            .then((response) => {
                console.log(response.data); // Should log "Backend is working!"
            })
            .catch((error) => {
                console.error('Error connecting to backend:', error);
            });
    }, []);

    return (
        <Router>
            <div className="bg-darkGrey min-h-screen text-safeguardWhite">
                <Header />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        {/* Root Route */}
                        <Route path="/" element={<Navigate to={authToken ? '/dashboard' : '/login'} />} />

                        {/* Authentication Routes */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />

                        {/* Dashboard with Nested Routes */}
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<Calendar />} />
                            <Route path="calendar" element={<Calendar />} />
                            <Route path="pricing" element={<QRPricingTags />} />
                            <Route path="dynamic-pricing" element={<DynamicPricingTags />} />
                            <Route path="loan-calculator" element={<LoanCalculator />} />
                            <Route path="crm" element={<CRM />} />
                            <Route path="financing" element={<Financing />} />
                            <Route path="settings" element={<AccountSettings />} />
                            <Route path="workflow" element={<WorkflowBoard />} /> {/* Added WorkflowBoard */}
                        </Route>

                        {/* 404 Fallback Route */}
                        <Route path="*" element={<Navigate to={authToken ? '/dashboard' : '/login'} />} />
                    </Routes>
                </Suspense>
            </div>
        </Router>
    );
}

export default App;