import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext'; // Use the AuthContext you set up earlier

const PrivateRoute = ({ children }) => {
    const { authToken } = useContext(AuthContext);

    // If no authToken, redirect to login
    return authToken ? children : <Navigate to="/login" />;
};

export default PrivateRoute;

