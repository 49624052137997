import axios from 'axios';

// Use REACT_APP_BACKEND_URL from environment variables
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL 
        ? `${process.env.REACT_APP_BACKEND_URL}/api` 
        : 'http://localhost:3001/api', // Fallback to localhost if not set
});

// Add interceptors for debugging
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken'); // Dynamically add token
    if (token) {
        config.headers['x-auth-token'] = token;
    }
    console.log('Outgoing Request:', config);
    return config;
}, (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
});

api.interceptors.response.use((response) => {
    console.log('Incoming Response:', response);
    return response;
}, (error) => {
    console.error('Response Error:', error.response || error);
    return Promise.reject(error);
});

// Register User
export const registerUser = async (userData) => {
    try {
        const response = await api.post('/auth/register', userData);
        return response.data;
    } catch (error) {
        console.error('Error registering user:', error.response?.data || error.message);
        throw error;
    }
};

// Login User
export const loginUser = async (userData) => {
    try {
        const response = await api.post('/auth/login', userData);
        return response.data;
    } catch (error) {
        console.error('Error logging in user:', error.response?.data || error.message);
        throw error;
    }
};

// Get Protected Data
export const getProtectedData = async (token) => {
    if (!token) throw new Error('Token is required');
    try {
        const response = await api.get('/auth/protected', {
            headers: { 'x-auth-token': token },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching protected data:', error.response?.data || error.message);
        throw error;
    }
};

export default api;